import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InformationMessage, PrimaryButton, SvgIcon } from '../../../../components';
import {
    MobileKeyActivationAvailability,
    MobileKeyStatus,
} from '../../../../modules/Admin/UsersAdmin/components/AccessControl/utils';
import { useReassignDigitalMobileKeyByUser } from '../../../../modules/Admin/UsersAdmin/components/UserAdministrationSidesheet/ApiQueries/useAccessControlInfo';
import AccessControlMobileKeyModal from './AccessControlMobileKeyModal';
import AccessControlPinModal from './AccessControlPinModal';

const Container = styled.div`
    background-color: var(--background-color);
    padding: 0.5rem;
    width: 100%;
    height: 100%;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 0.857rem;
    flex-direction: column;
`;

const StyledButton = styled(PrimaryButton)``;

const ButtonText = styled.span`
    font-weight: 700;
    font-size: 1.142rem;
    line-height: 1.714rem;
    margin-left: 0.571rem;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1.714rem 1.142rem;
`;

const StyledTitle = styled.p`
    font-weight: 700;
    font-size: 1.142rem;
    line-height: 1.714rem;
    margin-bottom: 1.142rem;
`;

const StyledDescription = styled.p`
    line-height: 1.714rem;
    margin-top: -0.571rem;
`;

const StyledInformationMessageWrapper = styled.div`
    width: 100%;
    padding: 0 2rem;
    position: fixed;
    bottom: 4rem;
    left: 0;
    animation: appear 0.5s linear;

    @keyframes appear {
        0% {
            opacity: 0;
            bottom: -1rem;
        }
        60% {
            bottom: 5rem;
        }
        80% {
            bottom: 4rem;
            opacity: 1;
        }
    }
`;

enum InfoMessageType {
    Error = 'error',
    Success = 'success',
}
enum InfoMessageAction {
    Reassign = 'reassign',
}
type InfoMessageConfig = {
    type: InfoMessageType;
    action: InfoMessageAction;
};
interface AccessComponentProps {
    isPinCodeEnabled: boolean;
    anyReassignmentAvailable: boolean;
    mobileKeyActivationAvailabilities: MobileKeyActivationAvailability[];
}
const AccessComponent = ({
    isPinCodeEnabled,
    anyReassignmentAvailable,
    mobileKeyActivationAvailabilities,
}: AccessComponentProps): ReactElement => {
    const [showChangeAccessModal, setShowChangeWifiPasswordModal] = useState(false);
    const [selectedSystemForAction, setSelectedSystemForAction] = useState<string | null>(null);
    const { t } = useTranslation('myProfile');
    const [reassignDigitalMobileKey, { isLoading: isReassigningMobileKey }] = useReassignDigitalMobileKeyByUser();
    const [infoMessage, setInfoMessage] = useState<InfoMessageConfig | null>(null);

    const messageConfigSetter = (type: InfoMessageType, action: InfoMessageAction): void => {
        setInfoMessage({
            type: type,
            action: action,
        });
    };
    const handleReassignMobileKey = (accessSystem: string) => {
        setInfoMessage(null);

        reassignDigitalMobileKey([accessSystem], {
            onSuccess: () => {
                setSelectedSystemForAction(null);
                messageConfigSetter(InfoMessageType.Success, InfoMessageAction.Reassign);
            },
            onError: () => {
                setSelectedSystemForAction(null);
                messageConfigSetter(InfoMessageType.Error, InfoMessageAction.Reassign);
            },
        });
    };

    const checkIsLoading = (accessSystem: string) => selectedSystemForAction === accessSystem && isReassigningMobileKey;
    const checkIsDisabled = (accessSystem: string) =>
        selectedSystemForAction !== accessSystem && isReassigningMobileKey;

    const getMessageText = () => {
        if (infoMessage?.type === InfoMessageType.Success) {
            return infoMessage.action === InfoMessageAction.Reassign && t('mobileKeyReassignSuccessMessage');
        }
        if (infoMessage?.type === InfoMessageType.Error) {
            return infoMessage.action === InfoMessageAction.Reassign && t('mobileKeyReassignErrorMessage');
        }
    };

    const getChangePinButtons = () => {
        return (
            <>
                <ContentContainer>
                    <StyledTitle>{t('manageAccessControlPin')}</StyledTitle>
                    <StyledButton onClick={() => setShowChangeWifiPasswordModal(true)}>
                        <SvgIcon name="KeyIcon" />
                        <ButtonText>{t('changePin')}</ButtonText>
                    </StyledButton>
                </ContentContainer>
                {showChangeAccessModal && (
                    <AccessControlPinModal
                        open={showChangeAccessModal}
                        onClose={() => setShowChangeWifiPasswordModal(false)}
                    />
                )}
            </>
        );
    };

    const getDigitalKeyButtons = () => {
        return (
            <>
                <ContentContainer>
                    <StyledTitle>{t('mobileKeyTitle')}</StyledTitle>
                    <StyledDescription>{t('mobileKeyDescription')}</StyledDescription>
                    <ButtonContainer>
                        {mobileKeyActivationAvailabilities?.map((accessSystem) => {
                            if (accessSystem.mobileKeyStatus === MobileKeyStatus.CanReassign) {
                                return (
                                    <>
                                        <StyledButton
                                            onClick={() => setSelectedSystemForAction(accessSystem.id)}
                                            key={accessSystem.id}
                                            loading={checkIsLoading(accessSystem.id)}
                                            disabled={checkIsDisabled(accessSystem.id)}
                                        >
                                            <ButtonText>
                                                {t('mobileKeyButtonText', { accessSystemName: accessSystem.name })}
                                            </ButtonText>
                                        </StyledButton>
                                        {checkIsLoading(accessSystem.id) && <p>{t('mobileKeyReassignDescription')}</p>}
                                    </>
                                );
                            }
                        })}
                    </ButtonContainer>
                </ContentContainer>

                {selectedSystemForAction && !isReassigningMobileKey && (
                    <AccessControlMobileKeyModal
                        open={!!selectedSystemForAction && !isReassigningMobileKey}
                        onClose={() => setSelectedSystemForAction(null)}
                        accessSystemId={selectedSystemForAction}
                        reassignFunction={handleReassignMobileKey}
                    />
                )}
            </>
        );
    };

    return (
        <Container>
            {isPinCodeEnabled && getChangePinButtons()}
            {anyReassignmentAvailable && getDigitalKeyButtons()}
            {infoMessage && (
                <StyledInformationMessageWrapper>
                    <InformationMessage type={infoMessage.type} onClose={() => setInfoMessage(null)}>
                        {getMessageText()}
                    </InformationMessage>
                </StyledInformationMessageWrapper>
            )}
        </Container>
    );
};
export default AccessComponent;
