import { useContext } from 'react';
import type { AxiosError, AxiosResponse } from 'axios';
import type { InfiniteQueryResult, MutationResultPair, QueryResult } from 'react-query';
import { useInfiniteQuery, useMutation, useQuery, useQueryCache } from 'react-query';
import {
    Ticket,
    TicketPriority,
    TicketStatus,
    UpdateTicket,
    CreateTicketDto,
    TicketUserCount,
    TicketSortingType,
    TicketSortDirection,
    TicketOptionType,
    PreparedFiltersType,
    TicketGridResult,
    TicketTypeAndCategoryOption,
} from '../../../api/apiTypes/ticketingApiTypes';
import {
    getReporteeTickets,
    getTicket,
    updateTicket,
    getTicketStatuses,
    getTicketPriorities,
    createTicket,
    getUserTicketCount,
    getTicketAssignees,
    getTicketCompanies,
    getTicketTypesOptions,
} from '../../../api/ticketingApi';
import { TicketListView } from '../components/UserTickets/UserTickets';
import { TicketingContext } from '../Ticketing';
import { getTicketingSettings } from '../utils';
import { TicketActivityCacheKey } from './useTicketActivities';

const ReporteeTicketsCacheKey = 'ReporteeTicketsCacheKey';
export const TicketCacheKey = 'TicketCacheKey';
const TicketStatusCacheKey = 'TicketStatusCacheKey';
const TicketPriorityCacheKey = 'TicketPriorityCacheKey';
const TicketUserCountCacheKey = 'TicketUserCountCacheKey';
const TicketCompanyCacheKey = 'TicketCompanyCacheKey';
const TicketAssigneeCacheKey = 'TicketAssigneeCacheKey';
const TicketOptionsTypeQueryCacheKey = 'TicketOptionsType';
const TicketCaseworkerOptionsTypeQueryCacheKey = 'TicketCaseworkerOptionsTypeQueryCacheKey';

export const useGetInfiniteReporteeTickets = (
    reporteeId: string,
    ticketSortingType: TicketSortingType,
    sort: TicketSortDirection | null,
    view: TicketListView,
    searchTerm = '',
    filters: PreparedFiltersType[],
): InfiniteQueryResult<TicketGridResult, Error | AxiosError<string>> => {
    const numberOfTickets = 20;
    return useInfiniteQuery<TicketGridResult, Error | AxiosError<string>>(
        [ReporteeTicketsCacheKey, reporteeId, ticketSortingType, sort, view, searchTerm, filters],
        async (_, reporteeId, ticketSortingType, sort, view, searchTerm, filters, page): Promise<TicketGridResult> => {
            const response = await getReporteeTickets(
                reporteeId,
                page ?? 0,
                numberOfTickets,
                ticketSortingType,
                sort,
                view,
                searchTerm,
                filters,
            );

            return response.data;
        },
        {
            getFetchMore: (lastPage, allPages) => {
                if (lastPage.data.length !== numberOfTickets) {
                    return false;
                }
                return allPages.length;
            },
            refetchInterval: 1000 * 20,
        },
    );
};

export const useGetTicket = (ticketId: string): QueryResult<Ticket, string | AxiosError<string> | Error> => {
    return useQuery([TicketCacheKey, ticketId], async () => {
        const result = await getTicket(ticketId);
        return result.data;
    });
};

export const useUpdateTicket = (
    shouldInvalidate = true,
): MutationResultPair<AxiosResponse<Ticket>, string | AxiosError<string> | Error, [string, UpdateTicket], never> => {
    const { state: TicketingContextState } = useContext(TicketingContext);
    const { sortingType, sortingDirection } = getTicketingSettings(TicketingContextState);
    const cache = useQueryCache();
    const { ticketListView, reporteeId } = TicketingContextState;
    return useMutation(
        ([id, ticket]) => {
            return updateTicket(id, ticket);
        },
        {
            onSuccess: ({ data: updatedTicket }) => {
                if (shouldInvalidate) {
                    const queryKey = [
                        ReporteeTicketsCacheKey,
                        reporteeId,
                        sortingType,
                        sortingDirection,
                        ticketListView,
                    ];
                    cache.setQueryData<Ticket[][] | undefined>(queryKey, (data) =>
                        data?.map((ticketsArr) =>
                            ticketsArr.map((ticket) => (ticket.id === updatedTicket.id ? updatedTicket : ticket)),
                        ),
                    );
                    cache.invalidateQueries(TicketCacheKey);
                    cache.invalidateQueries(TicketActivityCacheKey);
                }
            },
        },
    );
};

export const useCreateTicket = (): MutationResultPair<
    AxiosResponse<string>,
    string | AxiosError<string> | Error,
    [CreateTicketDto, string, string],
    never
> => {
    const cache = useQueryCache();

    return useMutation(
        ([ticket, typeId, categoryId]) => {
            return createTicket(ticket, typeId, categoryId);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(TicketCacheKey);
            },
        },
    );
};

export const useGetTicketStatuses = (): QueryResult<TicketStatus[], string | AxiosError<string> | Error> => {
    return useQuery([TicketStatusCacheKey], async () => {
        const result = await getTicketStatuses();
        return result.data;
    });
};

export const useGetTicketPriorities = (): QueryResult<TicketPriority[], string | AxiosError<string> | Error> => {
    return useQuery([TicketPriorityCacheKey], async () => {
        const result = await getTicketPriorities();
        return result.data;
    });
};

export const useGetTicketAssignees = (): QueryResult<TicketOptionType[], string | AxiosError<string> | Error> => {
    return useQuery([TicketAssigneeCacheKey], async () => {
        const result = await getTicketAssignees();
        return result.data;
    });
};

export const useGetTicketCompanies = (): QueryResult<TicketOptionType[], string | AxiosError<string> | Error> => {
    return useQuery([TicketCompanyCacheKey], async () => {
        const result = await getTicketCompanies();
        return result.data;
    });
};

export const useTicketingOptionsType = (): QueryResult<
    TicketTypeAndCategoryOption[],
    string | AxiosError<string> | Error
> => {
    return useQuery(
        [TicketOptionsTypeQueryCacheKey],
        async () => {
            const result = await getTicketTypesOptions();
            return result.data;
        },
        {
            staleTime: 1000 * 20,
        },
    );
};

export const useGetUserTicketCount = (
    view: TicketListView,
    reporteeId?: string,
    status?: TicketStatus,
): QueryResult<TicketUserCount, string | AxiosError<string> | Error> => {
    return useQuery([TicketUserCountCacheKey, reporteeId, status, view], async () => {
        let reporteeIdParam = reporteeId;
        const getCompanyCount = view === TicketListView.CompanyTickets;
        if (view === TicketListView.Worklist || view === TicketListView.CompanyTickets) reporteeIdParam = undefined;
        const result = await getUserTicketCount(reporteeIdParam, status, getCompanyCount);
        return result.data;
    });
};
