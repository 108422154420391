import { ReactElement, useState, useEffect } from 'react';
import { startOfWeek, add } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { Perm } from '../../../../common';
import { SvgIcon, ErrorMessage, PrimaryButton } from '../../../../components';
import { useUserHasPerm } from '../../../../utility';
import { getDeviceLanguageDateFormat } from '../../../../utility/dateUtilities/getDeviceLanguageDateFormat';
import { usePortalContentConfig } from '../../usePortalContentConfig';
import { useDailyMenus } from '../useDailyMenu';
import { MenuImageEditing, MenuForm, EditFoodMenuActionBar } from './components';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ContentContainer = styled.div`
    max-width: 65rem;
    margin: 1.7rem;
`;

const Card = styled.div`
    border-radius: 0.71rem;
    padding: 1.71rem;
    box-shadow: 0px 4px 10px 0px #00000026;
    overflow-y: auto;
    margin-bottom: 24px;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    height: min-content;
    background: var(--surface-color-light);
`;

const FlexContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    & :last-child {
        max-height: 40px;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Heading = styled.h2`
    font-size: 1.42rem;
    line-height: 2rem;
    color: var(--text-high-emphasis-color);
    font-weight: bold;
    margin-bottom: 2.3rem;
`;

const MenuNavigationButton = styled.button`
    background: none;
    border: none;
    display: flex;
    height: fit-content;
    transform: rotate(${({ invert }: { invert?: boolean }) => (invert ? `0.5turn` : '0')});
    cursor: pointer;
    padding: 0;

    &[disabled] {
        cursor: default;
    }

    &[disabled] svg {
        opacity: 0.5;
    }
`;

const WeekHeader = styled.h3`
    font-size: 1.42rem;
    margin: 0;
`;

const WeekDates = styled.h4`
    font-size: 0.8rem;
    letter-spacing: 0.01rem;
    margin: 0;
    color: var(--text-medium-emphasis-color);
`;

const WeekDetails = styled.div`
    margin: 0 1.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LoaderContainer = styled.div`
    width: 100%;
    padding: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const formId = 'EditMenuForm';

export enum RootLocation {
    Dashboard = 'Dashboard',
    SideBar = 'SideBar',
}
interface LocationState {
    page: RootLocation;
}
const EditDailyMenu = (): ReactElement => {
    const { t } = useTranslation('Dashboard', { keyPrefix: 'dailyMenu' });
    const canEditPortalContentImage = useUserHasPerm(Perm.todaysMenuImageUpdate);
    const canEditDailyMenu = useUserHasPerm(Perm.todaysMenuUpdate);
    const [monday, setMonday] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
    const [isSaving, setIsSaving] = useState(false);
    const [formIsDirty, setFormIsDirty] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [showNavDisabled, setShowNavDisabled] = useState(false);
    const { data: weeksMenu, isLoading: isLoadingMenu } = useDailyMenus(monday);
    const { data: contentConfig, isLoading: isLoadingConfig } = usePortalContentConfig();

    const moveWeek = (forwards: boolean) => {
        setMonday((curr) => add(curr, { weeks: forwards ? 1 : -1 }));
    };
    const goToNextWeek = () => moveWeek(true);
    const goToPreviousWeek = () => moveWeek(false);

    const handleSetIsSaving = (isSaving: boolean) => setIsSaving(isSaving);
    const handleSetFormIsDirty = (isDirty: boolean) => setFormIsDirty(isDirty);
    const handleSetIsSuccess = (isSuccess: boolean) => setIsSuccess(isSuccess);
    const handleSetIsError = (isError: boolean) => setIsError(isError);
    const handleSetShowNavDisabled = () => setShowNavDisabled(true);
    const location = useLocation<LocationState>();
    const { page } = location.state || { page: 'Dashboard' };

    useEffect(() => {
        const resetErrorStateIfNotNeeded = () => {
            if (!isSaving && !formIsDirty) setShowNavDisabled(false);
        };

        resetErrorStateIfNotNeeded();
    });
    const navDisabled = isSaving || formIsDirty;
    const saveDisabled = isSaving || isLoadingMenu || !formIsDirty || isError;

    return (
        <Container>
            {page === RootLocation.Dashboard && <EditFoodMenuActionBar />}
            <ContentContainer>
                <Heading>{t('editDailyMenuTitle')}</Heading>
                {canEditPortalContentImage ? (
                    <Card>
                        {isLoadingConfig ? (
                            <LoaderContainer>
                                <Loader active inline>
                                    {t('loadingMenuImageMessage')}
                                </Loader>
                            </LoaderContainer>
                        ) : contentConfig ? (
                            <MenuImageEditing contentConfig={contentConfig} />
                        ) : (
                            <ErrorMessage message={t('errorLoadingImage')} />
                        )}
                    </Card>
                ) : null}
                {canEditDailyMenu ? (
                    <Card>
                        <FlexContainer>
                            <HeaderContainer onClick={navDisabled ? handleSetShowNavDisabled : undefined}>
                                <MenuNavigationButton
                                    disabled={navDisabled}
                                    invert
                                    onClick={goToPreviousWeek}
                                    aria-label={t('goToLastWeek')}
                                >
                                    <SvgIcon name="CaretRight" alt="" />
                                </MenuNavigationButton>
                                <WeekDetails>
                                    <WeekHeader>
                                        {t('weekNumber')}
                                        {`${getDeviceLanguageDateFormat(monday, 'I')}`}
                                    </WeekHeader>
                                    <WeekDates>
                                        {getDeviceLanguageDateFormat(monday, 'd/M')}-
                                        {getDeviceLanguageDateFormat(add(monday, { days: 4 }), 'd/M')}
                                    </WeekDates>
                                </WeekDetails>
                                <MenuNavigationButton
                                    disabled={navDisabled}
                                    onClick={goToNextWeek}
                                    aria-label={t('goToNextWeek')}
                                >
                                    <SvgIcon name="CaretRight" alt="" />
                                </MenuNavigationButton>
                            </HeaderContainer>
                            <PrimaryButton type="submit" form={formId} disabled={saveDisabled}>
                                {isSaving ? (
                                    t('storesButton')
                                ) : !formIsDirty && isSuccess ? (
                                    <>
                                        <SvgIcon name="CheckIcon" /> {t('savedButton')}
                                    </>
                                ) : (
                                    t('saveButton')
                                )}
                            </PrimaryButton>
                        </FlexContainer>
                        {isLoadingMenu ? (
                            <LoaderContainer>
                                <Loader active inline>
                                    {t('loadingMenuTextLoader')}
                                </Loader>
                            </LoaderContainer>
                        ) : weeksMenu ? (
                            <MenuForm
                                key={monday.toISOString()}
                                menus={weeksMenu}
                                formId={formId}
                                setIsSaving={handleSetIsSaving}
                                setFormIsDirty={handleSetFormIsDirty}
                                showNavDisabledError={navDisabled && showNavDisabled}
                                setIsSuccess={handleSetIsSuccess}
                                setIsError={handleSetIsError}
                            />
                        ) : (
                            <ErrorMessage message={t('errorLoadingMenuMessage')} />
                        )}
                    </Card>
                ) : null}
            </ContentContainer>
        </Container>
    );
};

export default EditDailyMenu;
