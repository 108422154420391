export const getApimUrl = (): string => {
    return process.env['REACT_APP_APIM'] ?? '';
};

export const getSentryDSN = (): string => {
    return process.env['REACT_APP_SENTRY_DSN'] ?? '';
};

export const getSentryEnvironment = (): string | null => {
    return process.env['REACT_APP_SENTRY_ENVIRONMENT'] ?? null;
};

export const getSentryReplayEnabled = (): boolean => {
    return process.env['REACT_APP_SENTRY_REPLAY_ENABLED'] === 'true';
};

export const getIdentityAppName = (): string => {
    return process.env['REACT_APP_IDENTITY_APP_NAME'] ?? '';
};

export const getIdentityAuthority = (): string => {
    return process.env['REACT_APP_IDENTITY_AUTHORITY'] ?? '';
};
