import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useGetNavigationUrl from './useGetNavigationUrl';

interface ApplicationHistory<State> {
    replace: (pathName: string, state?: State, isPortalChanges?: boolean) => void;
    push: (pathName: string, state?: State) => void;
    length: number;
    goBack(onNoHistory: () => void): void;
    navigate: (pathName: string, options?: { replace?: boolean; state?: State }) => void;
}

const useNavigation = <State,>(): ApplicationHistory<State> => {
    const history = useHistory();
    const getUrl = useGetNavigationUrl();

    const replace = useCallback(
        (path: string, state?: State, isPortalChanges?: boolean) => {
            if (isPortalChanges) {
                return history.replace(path, state);
            }

            return history.replace(getUrl(path), state);
        },
        [getUrl, history],
    );

    const push = useCallback(
        (path: string, state?: State) => {
            return history.push(getUrl(path), state);
        },
        [getUrl, history],
    );

    const goBack = useCallback(
        (onNoHistory: () => void) => {
            if (history.length > 1) {
                history.goBack();
            } else {
                onNoHistory();
            }
        },
        [history],
    );

    const navigate = useCallback(
        (path: string, options?: { replace?: boolean; state?: State }) => {
            if (options?.replace) {
                replace(path, options.state);
            } else {
                push(path, options?.state);
            }
        },
        [replace, push],
    );

    return useMemo(() => ({ ...history, replace, push, goBack, navigate }), [goBack, history, push, replace, navigate]);
};

export default useNavigation;
