import { type AxiosResponse } from 'axios';
import {
    AccessNode,
    AccessControlFormData,
    MobileKeyActivationAvailability,
    AccessSystemNode,
} from '../modules/Admin/UsersAdmin/components/AccessControl/utils';
import {
    AccessGroupType,
    AllAccessList,
    CompanyAccessList,
} from '../modules/Admin/UsersAdmin/components/UserAdministrationSidesheet/ApiQueries/useAccessControlInfo';
import { Asset } from './apiTypes/ticketingApiTypes';
import http from './infrastructure/httpService';
import { getBaseUrlApim, getPortalId } from './utils';

const urlAccess = process.env.REACT_APP_ACCESS_CONTROL_URL;

export const getAdminAvailableAccessSystemsForUser = (userId: string): Promise<AxiosResponse<AccessSystemNode[]>> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/system/all/user`;
    return http.get(url, {
        params: {
            searchedUser: userId,
        },
    });
};

export const getAvailableAccessSystemsForUserOwn = (): Promise<AxiosResponse<MobileKeyActivationAvailability[]>> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/system/all/user/own`;
    return http.get(url);
};

export const getAllAccessControls = (): Promise<AxiosResponse<AccessSystemNode[]>> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/system/all`;
    return http.get(url);
};
export const getAllBookableObjectAccessList = (
    bookableObjectId: string,
): Promise<AxiosResponse<AccessSystemNode[]>> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/system/all/bookableObject/${bookableObjectId}`;
    return http.get(url);
};

export function updatedUserAccess(
    updatedAccess: AccessControlFormData[],
    authenticatedUser: string,
    addedUserId: string,
): Promise<AxiosResponse<AccessNode[]>> {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/group/user/list`;
    return http.post(url, updatedAccess, {
        params: { authenticatedUser, addedUserId },
    });
}

export const getCompanyAccessControls = (companyId: string): Promise<AxiosResponse<Asset[]>> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/group/company/${companyId}`;
    return http.get(url);
};

export const createNewPin = (pin: string): Promise<AxiosResponse<string>> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/system/pincode`;

    return http.post(url, { pinCode: pin });
};

export const createUserMobileKey = (accessSystemId: string, userId: string): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/system/${accessSystemId}/mobilekey`;
    return http.post(url, { userId });
};

export const createUserMobileKeyByUser = (accessSystemId: string): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/system/${accessSystemId}/mobilekey`;
    return http.post(url);
};

export const deleteUserMobileKey = (accessSystemId: string, userId: string): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/system/${accessSystemId}/mobilekey`;
    return http.delete(url, { data: { userId } });
};

export const deleteUserMobileKeyByUser = (accessSystemId: string): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/system/${accessSystemId}/mobilekey`;
    return http.delete(url);
};

export const reassignUserMobileKey = (accessSystemId: string, userId: string): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/system/${accessSystemId}/reassignmobilekey`;
    return http.post(url, { userId });
};

export const reassignUserMobileKeyByUser = (accessSystemId: string): Promise<AxiosResponse> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/system/${accessSystemId}/reassignmobilekey/own`;
    return http.post(url);
};

export const updateAccessGroupsInCompany = ({
    accessGroups,
}: {
    accessGroups: AccessGroupType;
}): Promise<AxiosResponse<CompanyAccessList[]>> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/group/company/list`;
    return http.put(url, accessGroups);
};

export const getAllAccessGroups = (): Promise<AxiosResponse<AllAccessList[]>> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/group/all`;
    return http.get(url);
};

export const getPincodeIsEnabled = async (): Promise<AxiosResponse<boolean>> => {
    const url = `${getBaseUrlApim()}/${urlAccess}/${getPortalId()}/system/pincode/isEnabled`;
    return http.get(url);
};
