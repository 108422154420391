import React, { createContext, PropsWithChildren, ReactElement, useEffect, useMemo, useReducer } from 'react';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { getOwnUserInfo } from '../../app/api/portalApi';
import { ApiAuthorizationRoutes, ApplicationPaths, QueryParameterNames } from '../../app/common/Authorization';
import { useNavigation } from '../../app/utility';
import { getIdentityAppName, getIdentityAuthority } from '../../app/utility/envReader';
import { getBrowserLanguage } from '../../app/utility/languageUtilities/getBrowserLanguage';
import { getUserLanguage } from '../../app/utility/languageUtilities/getUserLanguage';
import { appOperations } from '../../duck';
import { reducer, initialState } from './reducer';
import { CurrentUserState, CurrentUserContextValue, CurrentUserContextAction } from './types';

const settings = {
    applicationName: getIdentityAppName(),
    authority: getIdentityAuthority(),
    redirect_uri: window.location.origin + ApplicationPaths.LoginCallback,
    silent_redirect_uri: window.location.origin + ApplicationPaths.SilentCallback,
    post_logout_redirect_uri: window.location.origin + ApplicationPaths.LogOutCallback,
    popupWindowFeatures: 'location=yes,closebuttoncaption=,lefttoright=yes',
    response_type: 'code',
    client_id: 'LifeAtWork',
    scope: 'openid profile venicloud offline_access',
    monitorSession: false,
    includeIdTokenInSilentRenew: true,
    userStore: new WebStorageStateStore({
        prefix: getIdentityAppName(),
        store: window.localStorage,
    }),
};

export const userManager = new UserManager(settings);

export const CurrentUserContext = createContext<CurrentUserContextValue | null>(null);

export const CurrentUserProvider = ({ children }: PropsWithChildren<unknown>): ReactElement => {
    const { i18n } = useTranslation();
    const usersBrowserLanguage = i18n.resolvedLanguage;

    const reduxDispatch = useDispatch();
    const [state, dispatch] = useReducer<React.Reducer<CurrentUserState, CurrentUserContextAction>>(
        reducer,
        initialState,
    );
    const { replace } = useNavigation();

    useEffect(() => {
        const updateUser = () => {
            userManager?.events?.addUserLoaded(async (user) => {
                const portalId = user.profile.portalId || window.localStorage.getItem('portalId');
                dispatch({ type: 'RECEIVED_CURRENT_USER', payload: null });
                const userInfo = (await getOwnUserInfo(portalId)).data;
                dispatch({ type: 'RECEIVED_CURRENT_USER', payload: userInfo });
                userManager.startSilentRenew();
            });
            userManager?.events?.addUserUnloaded(() => {
                dispatch({ type: 'RECEIVED_CURRENT_USER', payload: null });
                userManager.stopSilentRenew();
            });
            userManager?.events?.addSilentRenewError((error) => {
                console.error('Silent renew error', error);
                replace(`${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${window.location.pathname}`);
            });
        };
        updateUser();

        const language = getUserLanguage(state.currentUser?.language) ?? getBrowserLanguage(usersBrowserLanguage);
        reduxDispatch(appOperations.setSelectedLang(language));
        i18n.changeLanguage(language);
    }, [replace, state.currentUser?.language]);

    const value: CurrentUserContextValue = useMemo(
        () => ({ state, userManager, dispatch }),
        [state, userManager, dispatch],
    );

    return (
        <CurrentUserContext.Provider value={value}>
            <Switch>
                <Route path={ApplicationPaths.ApiAuthorizationPrefix}>
                    <ApiAuthorizationRoutes />
                </Route>
                <Route>{children}</Route>
            </Switch>
        </CurrentUserContext.Provider>
    );
};
