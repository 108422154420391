import { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IRootStateProps } from '../../../reducers';
import { ModuleName } from '../../api/apiTypes/portalApiTypes';
import { Tabs } from '../../components';
import { Tab as TabType } from '../../components/navigation/Tabs/Tabs';
import { isWifiAtWork } from '../../helpers/isService';
import { MobileKeyStatus } from '../../modules/Admin/UsersAdmin/components/AccessControl/utils';
import {
    useGetMobileKeyActivationAvailabilitiesByUser,
    useGetPincodeIsEnabled,
} from '../../modules/Admin/UsersAdmin/components/UserAdministrationSidesheet/ApiQueries/useAccessControlInfo';
import { SidesheetContext, useModuleIsActive } from '../../utility';
import { SidesheetActionNames } from '../../utility/Sidesheet/context/types';
import PrivacyPolicyModal from '../ApprovePrivacyPolicy/components/PrivacyPolicyModal';
import useCurrentUserContext from '../Contexts/useCurrentUserContext';
import { Service } from '../lifeAtWorkServices';
import { useGetUserByIdInfo } from './apiQueries/useGetUserByIdInfo';
import AccessComponent from './components/AccessComponent';
import PasswordComponent from './components/PasswordComponent';
import PrivacyComponent from './components/PrivacyComponent';
import Profile from './components/Profile';
import VisitorRegistrationComponent from './components/VisitorRegistrationComponent';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--background-color);
`;

export enum ProfileTabs {
    Profile = 'Profile',
    Privacy = 'Privacy',
    Password = 'Password',
    Access = 'Access',
    VisitorRegistration = 'VisitorRegistration',
}

export interface MyProfileProps {
    defaultTab?: ProfileTabs;
}

const MyProfile = ({ defaultTab }: MyProfileProps): ReactElement => {
    const { t } = useTranslation('myProfile');

    const tabs = [
        { id: ProfileTabs.Profile, label: t('profile') },
        { id: ProfileTabs.Privacy, label: t('privacy') },
    ];

    const services = useSelector<IRootStateProps, Service[]>((state) => state.app.services);
    const { dispatch } = useContext(SidesheetContext);
    const { state } = useCurrentUserContext();
    const [showPrivacyAgreement, setShowPrivacyAgreement] = useState(false);
    const wifiAtWorkModuleIsActivated = useModuleIsActive(ModuleName.WifiAtWork);
    const wifiAtWorkIsActivated = !!(wifiAtWorkModuleIsActivated && services.find(isWifiAtWork));
    const accessControlIsActivated = useModuleIsActive(ModuleName.AccessControl);
    const visitorRegistrationModuleActivated = useModuleIsActive(ModuleName.Visitation);
    const { data: userVisitorRegistrationInfo, isLoading: isVisitorRegistrationInfoLoading } = useGetUserByIdInfo();
    const { visitationAvailability: { userVisitationEnabled = false, allowVisitationChange = false } = {} } =
        userVisitorRegistrationInfo ?? {};

    const { data: mobileKeyActivationAvailabilities, isLoading: isMobileKeyAvailabilitiesLoading } =
        useGetMobileKeyActivationAvailabilitiesByUser();
    const { data: isPinCodeEnabled, isLoading: isPinCodeEnabledLoading } = useGetPincodeIsEnabled();

    const anyReassignmentAvailable = mobileKeyActivationAvailabilities?.some(
        (accessSystem) => accessSystem.mobileKeyStatus === MobileKeyStatus.CanReassign,
    );

    const isTabsLoading =
        isVisitorRegistrationInfoLoading || isMobileKeyAvailabilitiesLoading || isPinCodeEnabledLoading;

    const onSidesheetClose = () => {
        dispatch({ type: SidesheetActionNames.CloseSidesheet });
    };

    if (wifiAtWorkIsActivated) {
        tabs.push({ id: ProfileTabs.Password, label: t('wifi') });
    }

    if ((isPinCodeEnabled || anyReassignmentAvailable) && accessControlIsActivated) {
        tabs.push({ id: ProfileTabs.Access, label: t('access') });
    }

    if (visitorRegistrationModuleActivated && allowVisitationChange) {
        tabs.push({ id: ProfileTabs.VisitorRegistration, label: t('visitorRegistration') });
    }
    const getDefaultTab = (tabsArray: TabType[]) => tabsArray.find((item) => item.id === defaultTab) ?? tabsArray[0];

    const [selectedTab, setSelectedTab] = useState<TabType>(getDefaultTab(tabs));

    return (
        <Container>
            <Tabs
                tabs={tabs}
                selectedTab={selectedTab}
                onClickTab={(selectedTab) => setSelectedTab(selectedTab)}
                onCloseClick={onSidesheetClose}
                isLoading={isTabsLoading}
            ></Tabs>

            {selectedTab.id === ProfileTabs.Profile && state.currentUser && (
                <Profile user={state.currentUser} onClickPrivacyPolicy={() => setShowPrivacyAgreement(true)} />
            )}
            {selectedTab.id === ProfileTabs.Privacy && <PrivacyComponent />}
            {selectedTab.id === ProfileTabs.Password && <PasswordComponent />}
            {selectedTab.id === ProfileTabs.Access && (
                <AccessComponent
                    anyReassignmentAvailable={!!anyReassignmentAvailable}
                    isPinCodeEnabled={!!isPinCodeEnabled}
                    mobileKeyActivationAvailabilities={mobileKeyActivationAvailabilities || []}
                />
            )}
            {selectedTab.id === ProfileTabs.VisitorRegistration && (
                <VisitorRegistrationComponent isEnabled={userVisitationEnabled && allowVisitationChange} />
            )}
            <PrivacyPolicyModal
                open={showPrivacyAgreement}
                onClose={() => setShowPrivacyAgreement(false)}
                showApproveButton={false}
            />
        </Container>
    );
};

export default MyProfile;
