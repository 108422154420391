import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { getSentryDSN, getSentryEnvironment, getSentryReplayEnabled } from '../../utility/envReader';

export const UNKNOWN_TAG_NAME = 'unknown';

function init(): void {
    const history = createBrowserHistory();
    if (process.env.NODE_ENV === 'development' || !getSentryEnvironment()) return;

    const integrations = [
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
        Sentry.browserTracingIntegration(),
    ];

    if (getSentryReplayEnabled()) {
        integrations.push(
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: true,
            }),
        );
    }

    Sentry.init({
        dsn: getSentryDSN(),
        integrations,
        environment: getSentryEnvironment() as string,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

function configureTags(tags: { [key: string]: string }): void {
    const scope = Sentry.getCurrentScope();
    scope.setTags(tags);
}

function log(error: Error, details?: { key: string; extra: unknown }): void {
    Sentry.captureException(error);
    Sentry.setExtra('error details: ', details);
}

export default { init, log, configureTags };
